import React, { useEffect } from 'react'
import Glide from '@glidejs/glide'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'

const CarouselHomeHero = () => {
  const data = useStaticQuery(graphql`
    query {
      allHomeHeroCarouselYaml {
        nodes {
          slide {
            image {
              childrenImageSharp {
                gatsbyImageData(width: 2000, placeholder: BLURRED, formats: NO_CHANGE, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  `)

  const sliderb = new Glide('.glide-home-hero-carousel', {
    type: 'carousel',
    autoplay: 4000,
    gap: 0,
  })

  const numImages = data.allHomeHeroCarouselYaml.nodes.length
  let numImagesLoaded = 0
  const handleImagesLoaded = () => {
    numImagesLoaded++
    if (numImages === numImagesLoaded) {
      console.log('WE LOADED ALL IMAGE')
      sliderb.update()
    }
  }

  useEffect(() => {
    sliderb.mount()
  }, [])

  return (
    <section className="glide glide-home-hero-carousel position-absolute" style={{ zIndex: -1, top: '65px' }}>
      <div className="glide__track" data-glide-el="track">
        <div className="glide__slides">
          {data.allHomeHeroCarouselYaml.nodes.map((node, index) => {
            return (
              <div className="glide__slide" key={`"index"${index}`}>
                <GatsbyImage
                  image={getImage(node.slide.image.childrenImageSharp[0].gatsbyImageData)}
                  alt={node.slide.image?.altText}
                  onLoad={handleImagesLoaded}
                  className="order-sm-2"
                />
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default CarouselHomeHero
